import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const BlogPost = ({ body, date, image, title }) => {
  return (
    <main>
      <div className="max-w-5xl m-auto">
        <div className="my-40">
          <h1 className="text-white text-5xl font-bold my-4">{title}</h1>
          <h2 className="text-grey text-xl mb-8">{date}</h2>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-5xl m-auto mt-8">
          <div className="bg-white mb-20">
            <GatsbyImage
              image={image.gatsbyImageData}
              alt="maplecare"
              style={{ maxWidth: 1000, marginTop: "-8em" }}
            />
          </div>
          <div
            className="blog-body mx-8"
            dangerouslySetInnerHTML={{
              __html: body.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
    </main>
  );
};

const BlogPage = ({
  pageContext: { body, date, image, title, header, footer },
}) => {
  header.background = "bg-description";
  header.color = "text-white";
  return (
    <>
      <Helmet htmlAttributes={{ class: "bg-description" }}></Helmet>
      <Layout header={header} footer={footer}>
        <BlogPost {...{ body, date, image, title }} />
      </Layout>
    </>
  );
};

export default BlogPage;
