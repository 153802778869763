import * as React from "react";
import { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "./link";
import Hamburger from "./hamburger";

const Header = ({ logo, navigationLinks, background, color }) => {
  const [active, setActive] = useState(false);

  return (
    <div className={`sticky top-0 z-50 ${background || "bg-white"}`}>
      <div className="flex flex-col max-w-5xl m-auto">
        <div className="flex flex-row justify-between max-w-5xl min-w-full m-auto">
          <section>
            <Link to="/">
              <GatsbyImage image={logo.gatsbyImageData} alt="maplecare" />
            </Link>
          </section>
          <section className="flex flex-row sm:hidden">
            {navigationLinks.map(({ displayValue, slug }, i) => {
              return (
                <Link
                  activeClassName="text-button"
                  className={`mx-2 mt-10 text-xl cursor-pointer font-montserrat ${
                    color || "text-description"
                  }`}
                  key={i}
                  to={slug}
                >
                  {displayValue}
                </Link>
              );
            })}
          </section>
          <section className="hidden sm:flex">
            <Hamburger
              active={active}
              className="w-16 m-4 text-logo"
              onClick={() => setActive(!active)}
            />
          </section>
        </div>
        {active && (
          <div className="min-h-screen">
            {navigationLinks.map(({ displayValue, slug }, i) => {
              return (
                <div className="flex flex-row justify-center">
                  <Link
                    activeClassName="text-button"
                    className={`mx-2 mt-10 text-xl cursor-pointer font-montserrat ${
                      color || "text-description"
                    }`}
                    key={i}
                    to={slug}
                  >
                    {displayValue}
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
