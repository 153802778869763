import * as React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { QuestionContextContainer } from "../components/question-context";

const Layout = ({ header, footer, children }) => {
  return (
    <QuestionContextContainer>
      <Header {...header} />
      {children}
      <Footer {...footer} />
    </QuestionContextContainer>
  );
};

export default Layout;
