import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "./button";
import Link from "./link";

const Footer = ({ image, navigationLinks, callToAction, callToActionId }) => {
  return (
    <footer className="bg-footer py-10">
      <div className="hidden sm:flex flex-col justify-center m-auto">
        <section className="my-6">
          <Button>{callToAction}</Button>
        </section>
      </div>
      <div className="max-w-5xl m-auto flex flex-row justify-between sm:justify-center">
        <section>
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={"maplecare"}
            style={{ maxWidth: 100 }}
          />
        </section>
        <section className="mt-6 sm:hidden">
          <Button id={callToActionId}>{callToAction}</Button>
        </section>
        <section className="flex flex-col sm:hidden">
          <h1 className="mx-2 mt-4 text-xl text-white">Maplecare</h1>
          {navigationLinks.map(({ displayValue, slug }, i) => {
            return (
              <Link
                className="mx-2 mt-4 text-xl cursor-pointer text-grey"
                key={i}
                to={slug}
              >
                {displayValue}
              </Link>
            );
          })}
        </section>
      </div>
      <div>
        <section className="flex-col hidden sm:flex">
          <div className="flex flex-col justify-center m-auto">
            <h1 className="mx-2 mt-4 text-xl text-white">Maplecare</h1>
          </div>
          {navigationLinks.map(({ displayValue, slug }, i) => {
            return (
              <div className="flex flex-col justify-center m-auto">
                <Link
                  className="mx-2 mt-4 text-xl cursor-pointer text-grey"
                  key={i}
                  to={slug}
                >
                  {displayValue}
                </Link>
              </div>
            );
          })}
        </section>
      </div>
      <div className="max-w-5xl m-auto flex flex-row text-grey">
        <p>© 2022 Maple Care. All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
